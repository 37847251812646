import {
  COMMON_WALLET_ADD_DONE,
  COMMON_WALLET_ADDED,
  COMMON_WALLET_ADDING,
  COMMON_WALLET_REMOVE_DONE,
  COMMON_WALLET_REMOVED,
  COMMON_WALLET_REMOVING,
  COMMON_WALLETS_LIST_FETCHED,
  FETCHING_USER_SCORES,
  FETCHING_USER_SCORES_FAILED,
  FETCHING_WALLET_ADDRESSES_LIST,
  INIT_URL,
  PASSWORD_CHANGED,
  PASSWORD_CHANGING,
  PASSWORD_CHANGING_FAILED,
  PASSWORD_RESET_FAILED,
  PASSWORD_RESET_SUCCESS,
  SENDING_RESET_PASSWORD_REQUEST, SET_CAPTCHA_REQUIRED,
  SET_FORGET_PASSWORD_STEP,
  SET_SIGNIN_STEP,
  SET_SIGNUP_STEP,
  SIGNIN_FAILED,
  SIGNIN_SUCCESS,
  SIGNING_IN,
  SIGNING_UP,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_FAILED,
  SIGNUP_SUCCESS,
  SWITCH_ADD_WALLET_DIALOG,
  SWITCH_DELETE_WALLET_DIALOG,
  TWO_STEP_UPDATED,
  TWO_STEP_UPDATING,
  UPDATE_CAPTCHA_VERSION,
  UPDATE_TERMINAL_INFO,
  UPDATE_USER_SCORES,
  USER_DATA,
  USER_DATA_UPDATE,
  USER_SCORES_FETCHED,
  USER_TOKEN_EXPIRE,
  USER_TOKEN_SET
} from "../constants/ActionTypes";

const INIT_STATE = {
  token: localStorage.getItem('token'),//JSON.parse({}),//
  initURL: '',
  currentUser: JSON.parse(localStorage.getItem('userInfo')) ?? {},
  terminal: JSON.parse(localStorage.getItem('terminal')) ?? {},
  updating2FA: false,
  updatingPassword: false,

  signingIn: false,
  tokenReceiveTime: 0,
  signinStep: 1,
  signinVerifyDetails: {},

  signingUp: false,
  signupStep: 1,
  signupVerifyDetails: {},

  sendingForgetPasswordRequest: false,
  forgetPasswordStep: 1,
  forgetPasswordVerifyDetails: {},

  captchaVersion: 1,
  captchaRequired: false,

  passwordChanged: false,

  loadingCommonWallets: false,
  commonWallets: [],
  deletingCommonWallet: false,
  walletID: -1,
  addingWallet: false,
  deleteDialogOpened: false,
  addWalletDialogOpened: false,
  redraw: false,
  totalCommonWallets: 0,
  commonWalletAdded: false,

  walletAddress: "",
  walletNetwork: "",

  fetchingUserScores: false,
  userScores: {}


};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case UPDATE_CAPTCHA_VERSION: {
      return {...state, captchaVersion: state.captchaVersion + 1};
    }

    case UPDATE_TERMINAL_INFO: {
      localStorage.setItem("terminal", JSON.stringify(action.payload.terminal));
      return {...state, terminal: action.payload.terminal}
    }

    case SIGNING_IN: {
      return {...state, signingIn: true};
    }

    case SIGNIN_SUCCESS: {
      return {...state, signingIn: false, signinStep: action.payload.signinStep ?? state.signinStep,
                signinVerifyDetails: action.payload.signinVerifyDetails ?? state.signinVerifyDetails,
                captchaRequired: action.payload.captcha_required,
      };
    }

    case SET_CAPTCHA_REQUIRED: {
      return {...state, captchaRequired: action.payload.captcha_required,
      };
    }

    case SIGNIN_FAILED: {
      return {...state, signingIn: false, signinStep: action.payload.signinStep ?? state.signinStep,};
    }

    case SET_SIGNIN_STEP: {
      return {...state, signinStep: action.payload.step};
    }

    case SIGNING_UP: {
      return {...state, signingUp: true};
    }

    case SIGNUP_SUCCESS: {
      return {...state, signingUp: false, signupStep: action.payload.signupStep ?? state.signupStep,
        signupVerifyDetails: action.payload.signupVerifyDetails ?? state.signupVerifyDetails};
    }

    case SIGNUP_FAILED: {
      return {...state, signingUp: false, signupStep: action.payload.signupStep ?? state.signupStep,};
    }

    case SET_SIGNUP_STEP: {
      return {...state, signupStep: action.payload.step};
    }

    case SENDING_RESET_PASSWORD_REQUEST: {
      return {...state, sendingForgetPasswordRequest: true};
    }

    case PASSWORD_RESET_SUCCESS: {
      return {...state, sendingForgetPasswordRequest: false, forgetPasswordStep: action.payload.forgetPasswordStep ?? state.forgetPasswordStep,
        forgetPasswordVerifyDetails: action.payload.forgetPasswordVerifyDetails ?? state.forgetPasswordVerifyDetails};
    }

    case PASSWORD_RESET_FAILED: {
      return {...state, sendingForgetPasswordRequest: false, forgetPasswordStep: action.payload.forgetPasswordStep ?? state.forgetPasswordStep,};
    }

    case SET_FORGET_PASSWORD_STEP: {
      return {...state, forgetPasswordStep: action.payload.step};
    }

    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        currentUser: {},
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }

    case USER_DATA_UPDATE: {
      let user = state.currentUser;
      let newUser = Object.assign({}, user, action.payload)
      return {
        ...state,
        currentUser: {...newUser},
      };
    }

    case USER_TOKEN_SET: {
      return {...state, token: action.payload,};
    }

    case USER_TOKEN_EXPIRE: {
      return {
        ...state,
        token: null,
        currentUser: null,
      };
    }

    case TWO_STEP_UPDATING:
      return {...state, updating2FA: true};

    case PASSWORD_CHANGING:
      return {...state, changingPassword: true};

    case PASSWORD_CHANGED:
      return {...state, changingPassword: false, passwordChanged: true};

    case PASSWORD_CHANGING_FAILED:
      return {...state, changingPassword: false};

    case TWO_STEP_UPDATED:
      let user = state.currentUser;
      let newUser = Object.assign(user, {two_step_method: action.payload.twoStepMethod ?? user.two_step_method});
      return {...state, updating2FA: false, currentUser: {...newUser}};

    case COMMON_WALLET_ADDING: {
      return {...state, addingWallet: true};
    }
    case COMMON_WALLET_ADDED: {
      return {...state, addingWallet: false, commonWalletAdded: true,  redraw: true, addWalletDialogOpened: false};
    }
    case COMMON_WALLET_ADD_DONE: {
      return {...state, addingWallet: false, addWalletDialogOpened: false};
    }

    case FETCHING_WALLET_ADDRESSES_LIST: {
      return {...state, loadingCommonWallets: true, redraw: false};
    }
    case COMMON_WALLETS_LIST_FETCHED: {
      return {...state, loadingCommonWallets: false, redraw: false, commonWallets: action.payload.commonWallets ?? [], totalCommonWallets: action.payload.totalCommonWallets};
    }
    case COMMON_WALLET_REMOVING: {
      return {...state, deletingCommonWallet: true, walletID: action.payload.address_id};
    }
    case COMMON_WALLET_REMOVE_DONE: {
      return {...state, deletingCommonWallet: false};
    }
    case COMMON_WALLET_REMOVED: {
      let newWalletAddresses = state.commonWallets.filter(commonWallet => commonWallet.id !== state.walletID);

      return {...state, deletingCommonWallet: false, commonWallets: newWalletAddresses};
    }

    case SWITCH_DELETE_WALLET_DIALOG: {
      return {...state, deleteDialogOpened: action.payload.state, selectedOrder: {}, deletableID: action.payload.deletableID,
        selectedCurrency: action.payload.selectedCurrency, selectedBase: action.payload.selectedBase,
        initialAmount: action.payload.initialAmount ?? ''};
    }
    case SWITCH_ADD_WALLET_DIALOG: {
      return {...state, addWalletDialogOpened: action.payload, walletAddress: action.payload.walletAddress, walletNetwork: action.payload.walletNetwork};

    }
    case FETCHING_USER_SCORES: {
      return {...state, fetchingUserScores: true}
    }
    case USER_SCORES_FETCHED: {
      return {...state, fetchingUserScores: false, userScores: action.payload.scores.data}
    }
    case UPDATE_USER_SCORES: {
      let userScores = Object.assign({}, state.userScores);
      userScores.score = action.payload.score;
      return {...state, userScores: userScores}
    }
    case FETCHING_USER_SCORES_FAILED: {
      return {...state, fetchingUserScores: false}
    }
    default:
      return state;
  }
}
