export const MOBILE_MAX_WIDTH = 580;
export const TABLET_MAX_WIDTH = 991;
export const TABLET_MIN_WIDTH = 890;
export const AES_KEY = '9a10c9bcdc12693eb3cf566a90d3670bd0604a58dd758e146956baf596e97c7d';
export const DIGITAL_CURRENCIES = {
    USDT: 11,
    PM_USD: 12,
    PV_USD: 13,
    PM_EURO: 14,
    PV_EURO: 15,
    WM: 16,
    PAYMER: 17,
    IRR: 18,
    PS_VOUCHER: 19,
    PAYEER: 20,
    GOLD:     1193,
    UV_USD:   1194,
    UUSD:     1195,
    CRP:      1196,
    UV_CRP:   1197,
};

export const STATUS = {
    ACCEPTED_BY_ADMIN: 11,
    ACCEPTED_BY_EX_API: 12,
    ACCEPTED_BY_SYSTEM: 13,

    DEFAULT: 0,

    REJECTED_BY_ADMIN: -1,
    REJECTED_BY_EX_API: -2,
    REJECTED_BY_SYSTEM: -3,
    REJECTED_BY_USER: -4,
    EXPIRED: -5,

    ADMIN_PROGRESS: 1,
    EX_API_PROGRESS: 2,
    SYSTEM_PROGRESS: 3,
    USER_PROGRESS: 4,
};

export const TRANSFER_TYPES = {
    BUY: 1,
    SELL: 2,
    DEPOSIT: 5,
    WITHDRAW: 6,
    REFUND: 7,
    SUBSET_PROFIT: 8,
    PRIZE: 10,
};

export const PAYMENT_TYPES = {
    ORIGIN_TO_DESTINATION: 1,
    DESTINATION_TO_ORIGIN: 2,
};

export const AUTH_TYPE = {
    USER_INFO: 1,
    RESIDENTIAL: 2
};

export const GATEWAYS = {
    ONLINE: 1,
    C2C: 2,
    PERFECT_MONEY: 3,
    WEB_MONEY: 5,
    BINANCE: 6,
    OFFLINE: 7,
    UTOPIA: 13,
    UWALLET: 14,
    LEVANTS: 15

};

export const MANAGEMENT_UNITS = {
    3: "فنی",
    4: "مالی",
    5: "انتقادات و پیشنهادت"
};

export const GATEWAY_ERRORS = {
    ' 0': 'مشکلی هنگام ارتباط با درگاه به وجود آمده است. لطفا از طریق درگاه های دیگر اقدام نمائید.',
    '-1': 'پرداخت انجام نشد.  در صورت کسر مبلغ طی 24 ساعت آینده به حساب پرداخت کننده عودت داده خواهد شد.',
    '-2': 'شماره کارت پرداخت کننده در سایت یافت نشد. در صورت کسر مبلغ طی 24 ساعت آینده به حساب پرداخت کننده عودت داده خواهد شد.',
    '-3': 'پرداخت انجام نشد.  در صورت کسر مبلغ طی 24 ساعت آینده به حساب پرداخت کننده عودت داده خواهد شد.',
    '-4': 'مشکلی در ارتباط به درگاه به وجود آمده است. لطفا از طریق درگاه های دیگر اقدام نمائید.',
    '-5': 'ارز واریزی با مشخصات فاکتور مطابقت ندارد. لطفا از طریق پشتیبانی اقدام نمائید.',
    '-6': 'مقدار ارز واریزی با مشخصات فاکتور مطابقت ندارد. لطفا از طریق پشتیبانی اقدام نمائید.',
    '-7': 'این فاکتور قبلا تایید/رد شده است.',
};

export const NOTICE_UNITS = {
    ADMIN_NOTICE : 0,
    AFFILIATION : 1,
    VERIFY_INFO : 2,
    TICKET_ANSWERED : 3,
    TICKET_CLOSED : 4,
    WITHDRAW_SENT : 5,
    WITHDRAW_TIME_CHANGED : 6,
    WITHDRAW_CANCELED : 7,
    CARD_GRANTED : 8,
    AUTH_REJECTED : 9,
    AUTH_GRANTED : 10,
    VERIFY_MOBILE : 11,
    VERIFY_EMAIL : 12,
    EMAIL_VERIFIED : 13,
    SELL_ADMIN_PROGRESS : 14,
    BUY_ORDER_DONE : 15,
    PASSWORD_CHANGED : 16,
    '2FA_CHANGED' : 17,
    MAX_BUY_INCREASED : 18,
    SELL_ORDER_DONE : 19,
};
export const NOTICE_TYPES = {
    NOTICE_TYPE_NORMAL : 1,
    NOTICE_TYPE_WARNING: 2,
    NOTICE_TYPE_DANGER: 3
};

export const BRANCH_ESSENTIALS = {
    TERMINAL_ID : '1',
    TERMINAL_TOKEN : 'ffhg345G8G4Fgfs4gFDf34cvD409d'
};

export const TICKET_SHOW_TYPES = {
    NORMAL: 1,
    CHATBOX: 2
}

export const DISCOUNT_TYPE = {
    LEVEL_DISCOUNT: 1,
};

export const DISCOUNT_EFFECT_ON = {
    TOTAL: 1,
    COMMISSION: 2,
    BOTH: 3,
};

export const DISCOUNT_APPLY_AS = {
    DIRECT: 1,
    CODE: 2,
};

