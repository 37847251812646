import {
    CHANGE_CONTENT_FIXED,
    CHANGE_DIRECTION,
    CHANGE_HOME_NAVIGATION_STYLE,
    CHANGE_NAVIGATION_STYLE,
    DARK_THEME,
    DRAWER_TYPE,
    FIXED_DRAWER,
    HORIZONTAL_MENU_POSITION,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER,
    SWITCH_LANGUAGE,
    THEME_COLOR,
    TOGGLE_COLLAPSED_NAV,
    VERTICAL_NAVIGATION,
    WINDOW_WIDTH
} from '../constants/ActionTypes';
import {DRAGONS_FIGHT} from "../constants/ThemeColors";
import {global} from "../store/global";

const rltLocale = ['ar', 'fa'];
const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  themeColor: DRAGONS_FIGHT,
  darkTheme: localStorage.getItem('darkTheme') ? localStorage.getItem('darkTheme') === 'true' : false,
  width: window.innerWidth,
  isDirectionRTL: true,
  navigationStyle: VERTICAL_NAVIGATION,
  homeNavigationStyle: HORIZONTAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  isContentFixed: false,
  locale: {
    languageId: 'persian',
    locale: 'fa',
    name: 'Persian',
    icon: 'ir'
  }
};

global.locale = initialSettings.locale;

if(initialSettings.darkTheme) {
  const body = document.body.classList;
  body.toggle('dark-theme');
}

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)

      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL

      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };

    case CHANGE_HOME_NAVIGATION_STYLE:
      return {
        ...state,
        homeNavigationStyle: action.payload
      };


    case HORIZONTAL_MENU_POSITION:

      return {
        ...state,
        horizontalNavPosition: action.payload

      };

    case CHANGE_CONTENT_FIXED:
      return {
        ...state,
        isContentFixed: action.payload
      };
    case THEME_COLOR:
      localStorage.setItem('darkTheme', 'false');
      return {
        ...state,
        darkTheme: false,
        themeColor: action.color
      };
    case DARK_THEME:
      localStorage.setItem('darkTheme', (!state.darkTheme).toString());
      return {
        ...state,
        darkTheme: !state.darkTheme
      };


    default:
      return state;
  }
};

export default settings;
